import store from '@/store';
import moment from 'moment';

export const startDate = () => {
  var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const SUBSCRIPTIONS = store.state.funds.funds.user_requests_review_dates;
  if (SUBSCRIPTIONS.length > 1) {
    const comingStartDays = SUBSCRIPTIONS.reduce((acc, item) => {
      let dayOfWeek = days.indexOf(item);
      var currentDate = new Date();
      if (dayOfWeek !== currentDate.getDay()) {
        acc.push(currentDate.setDate(currentDate.getDate() + ((dayOfWeek + (7 - currentDate.getDay())) % 7)));
        return acc;
      }
      return acc;
    }, []);
    return Math.min(...comingStartDays);
  } else {
    var currentDate = new Date();
    return moment(currentDate).add(7, 'days');
  }
};

export default startDate;
