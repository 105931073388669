<template>
  <div class="StartInvest_root">
    <div class="StartInvest_wrapper">
      <div class="StartInvest_title">Start Investment</div>
      <div class="StartInvest_container">
        <div class="StartInvest_date-block">
          <div class="StartInvest_leftPart">Start date</div>
          <div class="StartInvest_rightPart">{{ calculateStartDate }}</div>
        </div>
        <div class="StartInvest_date-block">
          <div class="StartInvest_leftPart">End date</div>
          <div class="StartInvest_rightPart">{{ calculateEndDate }}</div>
        </div>
        <v-form class="StartInvest_form" ref="form" autocomplete="false">
          <div class="StartInvest_form-element">
            <Select
              :items="funds.invest_periods"
              :value="selectedPeriod"
              @change="onPeriodChange"
              name="period"
              label="choose investment period"
            />
          </div>
          <div class="StartInvest_form-element">
            <TextFieldInput
              :value="amountInvestment"
              @input="amountInvestment = arguments[0]"
              hideDetails
              name="amount"
              label="Amount investment"
              type="number"
              :rules="[...inputRules.required]"
            />
          </div>
          <div class="StartInvest_form-element">
            <Checkbox :value="reinvestCheck">
              <div class="StartInvest_checkbox-label" slot="label">Reinvest my dividends</div>
            </Checkbox>
          </div>
        </v-form>
        <div class="StartInvest_row">
          <div class="StartInvest_available">Available funds:</div>
          <div class="StartInvest_amount">{{ tradingBalance }} {{ funds.currency_name }}</div>
        </div>
        <OutlineButton
          class="outline-button-default outline-button-full-width outline-button outline-button"
          text="Invest now"
          fullWidth
          @click="handleSubmitInvest"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Select from '@/components/_common/FormElements/Select/Select';
import TextFieldInput from '@/components/_common/FormElements/TextFieldInput/TextFieldInput';
import Checkbox from '@/components/_common/FormElements/Checkbox/Checkbox';
import OutlineButton from '@/components/_common/FormElements/OutlineButton/OutlineButton';
import { startDate } from '../_helpers/calculateFundInvestmentDate';
import inputRules from '@/utils/inputRules';
import { validateForm } from '@/mixins/validateForm';
import moment from 'moment';

export default {
  components: {
    Select,
    Checkbox,
    OutlineButton,
    TextFieldInput,
  },
  data: () => ({
    selectedPeriod: '',
    amountInvestment: '',
    reinvestCheck: false,
    moment,
    inputRules,
  }),
  mixins: [validateForm],
  computed: {
    ...mapState('funds', ['funds']),
    ...mapState('balance', ['userTradingBalance']),
    calculateStartDate() {
      return moment(startDate()).format('DD.MM.YY');
    },
    calculateEndDate() {
      return moment(startDate())
        .add(+this.selectedPeriod[0], 'months')
        .format('DD.MM.YY');
    },
    tradingBalance() {
      // Temporary return EUR return
      if (this?.userTradingBalance?.[this.funds.currency_name]) {
        return (
          (
            this?.userTradingBalance?.[this.funds.currency_name]?.total - this?.userTradingBalance?.[this.funds.currency_name]?.reserved
          ).toFixed(4) || 0
        );
      }
      return 0;
    },
  },
  methods: {
    ...mapActions('funds', ['apiCreateFundInvestment']),
    onPeriodChange(value) {
      this.selectedPeriod = value.value;
    },
    handleSubmitInvest() {
      this.validateForm().then(() => {
        this.apiCreateFundInvestment({
          invest_fund_id: this.funds.id,
          is_dividend_reinvestment: this.reinvestCheck,
          amount: this.amountInvestment,
          period: +this.selectedPeriod[0],
          currency: this.funds.currency_name,
        });
      });
    },
  },
  mounted() {
    this.selectedPeriod = this.funds.invest_periods[0];
  },
};
</script>

<style lang="scss">
@import './StartInvest.scss';
</style>
