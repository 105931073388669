<template>
  <div class="AboutFund_root">
    <div class="AboutFund_wrapper">
      <div class="AboutFund_title">About fund</div>
      <div class="AboutFund_container">
        <div class="AboutFund_description">
          We offer reliable access to a variety of cryptocurrencies, including Bitcoin. We aim to track the monthly top list of the most
          valuable cryptocurrencies, while assessing them for specific risks and market capitalization. Our fund provides safety and
          simplicity on par with conventional investment institutions.
        </div>
        <div class="AboutFund_table">
          <div class="AboutFund_row">
            <div class="AboutFund_leftPart">Asset Class:</div>
            <div class="AboutFund_rightPart">Cryptocurrency</div>
          </div>
          <div class="AboutFund_row">
            <div class="AboutFund_leftPart">Inception date:</div>
            <div class="AboutFund_rightPart">26.07.2021</div>
          </div>
          <div class="AboutFund_row">
            <div class="AboutFund_leftPart">Assets Under Management:</div>
            <div class="AboutFund_rightPart">1 750 650 {{ funds.currency_name }}</div>
          </div>
          <div class="AboutFund_row">
            <div class="AboutFund_leftPart">Minimum investment:</div>
            <div class="AboutFund_rightPart">{{ funds.min_deposit_amount }} {{ funds.currency_name }}</div>
          </div>
          <div class="AboutFund_row">
            <div class="AboutFund_leftPart">Subscription:</div>
            <div class="AboutFund_rightPart">
              {{ fundInfoSubsribtion }}
            </div>
          </div>
          <div class="AboutFund_row">
            <div class="AboutFund_leftPart">Expense Ratio:</div>
            <div class="AboutFund_rightPart">{{ funds.management_percent_fee }}%</div>
          </div>
          <div class="AboutFund_row">
            <div class="AboutFund_leftPart">Performance Fee:</div>
            <div class="AboutFund_rightPart">{{ funds.success_result_percent_fee }}%</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  components: {},
  data: () => ({
    isAboutLoad: false,
  }),
  computed: {
    ...mapState('funds', ['funds']),
    fundInfoSubsribtion() {
      return this.funds && this.funds.user_requests_review_dates ? this.funds.user_requests_review_dates.join(', ') : '';
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import './AboutFund.scss';
</style>
