<template>
  <AppSection title="Fund">
    <div slot="content">
      <div class="Fund_root" v-if="fundsIsLoaded">
        <div class="Fund_wrapper">
          <div class="Fund_row">
            <InvestWallet @submitTransfer="transferPopupHandler" />
          </div>
          <div class="Fund_row">
            <InvestStatistic />
          </div>
          <div class="Fund_row">
            <AboutFund class="Fund_about" />
            <StartInvest class="Fund_invest" />
          </div>
        </div>
      </div>
      <transition name="fade" mode="out-in">
        <Modal :styles="`max-width: 540px; width: 100%;`" @close="transferModalShow = false" v-if="transferModalShow">
          <div slot="content">
            <TransferModal @close="transferModalShow = false" />
          </div>
        </Modal>
      </transition>
    </div>
  </AppSection>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AboutFund from './_components/AboutFund';
import StartInvest from './_components/StartInvest';
import InvestWallet from './_components/InvestWallet';
import InvestStatistic from './_components/InvestStatistic';
import TransferModal from './_components/TransferModal';
import Modal from '@/components/_common/Modal/Modal';
import AppSection from '@/components/_common/AppSection/AppSection';

export default {
  components: {
    AboutFund,
    AppSection,
    StartInvest,
    InvestWallet,
    InvestStatistic,
    TransferModal,
    Modal,
  },
  data: () => ({
    transferModalShow: false,
  }),
  computed: {
    ...mapState('funds', ['funds', 'fundsIsLoaded']),
  },
  methods: {
    ...mapActions('funds', ['apiFetchFundsList']),
    transferPopupHandler() {
      this.transferModalShow = !this.transferModalShow;
    },
  },
  mounted() {
    this.apiFetchFundsList();
  },
};
</script>

<style lang="scss" scoped>
@import './Fund.scss';
</style>
