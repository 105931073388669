<template>
  <div class="InvestWallet_root">
    <div class="InvestWallet_wrapper">
      <div class="InvestWallet_amount_col">
        <div class="InvestWallet_label">Investment Wallet</div>
        <div class="InvestWallet_amount">
          <div class="InvestWallet_amount-icon">
            <SvgIcon icon="iconCoins"></SvgIcon>
          </div>
          {{ tradingBalance }} {{ funds.currency_name }}
        </div>
      </div>
      <Button
        class="w-full md:w-[124px] !leading-[36px] !text-[12px]"
        text="transfer"
        @click="handleTransfer"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Button from '@/components/_common/FormElements/Button/Button';
import SvgIcon from "@/components/_common/SvgIcon";
export default {
  components: {
    Button,
    SvgIcon
  },
  computed: {
    ...mapState('balance', ['userTradingBalance']),
    ...mapState('funds', ['funds']),
    tradingBalance() {
      // Temporary return EUR & toFixed
      if (this?.userTradingBalance?.[this.funds.currency_name]) {
        return (
          (
            +this?.userTradingBalance?.[this.funds.currency_name]?.total - +this?.userTradingBalance?.[this.funds.currency_name]?.reserved
          ).toFixed(4) || 0
        );
      }
      return 0;
    },
  },
  methods: {
    handleTransfer() {
      this.$emit('submitTransfer');
    },
  },
};
</script>

<style lang="scss" scoped>
@import './InvestWallet.scss';
</style>
