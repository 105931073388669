<template>
  <div class="InvestStatistic_root">
    <div class="InvestStatistic_wrapper">
      <div class="InvestStatistic_part">
        <div class="InvestStatistic_block">
          <div class="InvestStatistic_fund-assets">
            <div class="InvestStatistic_fund-title">Fund assets</div>
            <div class="InvestStatistic_fund-amount">{{ funds.currency_name }} 1 750 650</div>
          </div>
        </div>
        <div class="InvestStatistic_septum" />
        <div class="InvestStatistic_currency">
          <div class="InvestStatistic_currency-wrapper">
            <div class="InvestStatistic_currency-icon">
              <SquareCoinIcon :currency="funds.currency_name" /><span> {{ funds.currency_name }}</span>
            </div>
            <div class="InvestStatistic_currency-statistic"><span>+</span>13.71%</div>
            <div class="InvestStatistic_currency-statistic"><span>+</span>205 650</div>
          </div>
        </div>
      </div>
      <div class="InvestStatistic_part">
        <div class="InvestStatistic_title">Share price changes</div>
        <div class="InvestStatistic_septum InvestStatistic_septum--margin" />
        <div class="InvestStatistic_row">
          <MonthRate
            v-for="(rate, index) in rates"
            :key="index"
            :rate="rate"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MonthRate from './MonthRate';
import SquareCoinIcon from '@/components/_common/SquareCoinIcon/SquareCoinIcon';
import { mapState } from 'vuex';

export default {
  components: {
    MonthRate,
    SquareCoinIcon,
  },
  data: () => ({
    rates: [
      {
        item: '1 month',
        value: '4.42%',
        type: 'plus',
      },
      {
        item: '3 months',
        value: '13.71%',
        type: 'plus',
      },
      {
        item: '6 months',
        value: '11.04%',
        type: 'plus',
      },
      {
        item: '1 year',
        value: '-',
        type: 'plus',
      },
    ],
  }),
  computed: {
    ...mapState('funds', ['funds']),
  },
  methods: {},
};
</script>

<style lang="scss">
@import './InvestStatistic.scss';
</style>
