<template>
  <label class="Checkbox_root">
    <slot name="label" />
    <input
      :type="inputType"
      :value="value"
      :disabled="disabled"
      :checked="value"
      :name="checkName"
      @input="handleInput"
    />
    <span class="Checkbox_checkmark"></span>
  </label>
</template>

<script>
export default {
  props: {
    inputType: {
      type: String,
      default: 'checkbox'
    },
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checkName() {
      if(this.inputType === 'radio') {
        return 'crypto_node'
      }

      return ''
    }
  },
  methods: {
    handleInput(event) {
      const isChecked = event.target.checked;
      this.$emit('input', isChecked);
    },
  },
};
</script>

<style lang="scss">
@import './Checkbox.scss';
</style>
