<template>
  <v-form class="TransferModal_root" ref="form" autocomplete="false">
    <h2 class="TransferModal_Title">transfer</h2>
    <div class="TransferModal_Wallets">
      <div class="TransferModal_Wallet">
        <Select :items="wallets" :value="walletFrom" name="wallet_from" label="from" @change="handleSwapWallets" />
        <span class="TransferModal_Available">Available: {{ transferBalance }} {{ funds.currency_name }}</span>
      </div>
      <div class="TransferModal_Wallet">
        <Select :items="wallets" :value="walletTo" name="wallet_to" label="to" @change="handleSwapWallets" />
      </div>
      <div class="TransferModal_Swap">
        <img src="@/assets/img/_common/investmentDots.svg" />
        <div class="TransferModal_SwapIcon" @click="handleSwapWallets">
          <div class="TransferModal_SwapIconBg">
            <img src="@/assets/img/_common/swapArrows.svg" alt="swap-icon" />
          </div>
        </div>
        <img src="@/assets/img/_common/investmentDots.svg" />
      </div>
    </div>
    <div class="TransferModal_Amount">
      <TextFieldInput
        type="number"
        name="amount"
        label="amount transaction"
        hideDetails
        placeholder="Amount"
        @input="walletAmount = arguments[0]"
        :value="walletAmount"
        :rules="[...inputRules.required]"
      />
    </div>
    <div class="TransferModal_Buttons">
      <OutlineButton
        class="outline-button-default outline-button-full-width outline-button"
        text="confirm"
        @click="handleCreateTransfer"
      />
      <Button
        class="btn min-w-[100px] ml-[10px]"
        size="large"
        text="cancel"
        @click="$emit('close')"
      />
    </div>
  </v-form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Select from '@/components/_common/FormElements/Select/Select';
import TextFieldInput from '@/components/_common/FormElements/TextFieldInput/TextFieldInput';
import OutlineButton from '@/components/_common/FormElements/OutlineButton/OutlineButton';
import Button from '@/components/_common/FormElements/Button/Button';
import inputRules from '@/utils/inputRules';
import { validateForm } from '@/mixins/validateForm';

export default {
  components: {
    Select,
    TextFieldInput,
    OutlineButton,
    Button,
  },
  data: () => ({
    wallets: ['Exchange wallet', 'Investment wallet'],
    walletFrom: 'Exchange wallet',
    walletTo: 'Investment wallet',
    walletAmount: '',
    inputRules,
  }),
  mixins: [validateForm],
  computed: {
    ...mapState('balance', ['balance', 'userTradingBalance']),
    ...mapState('funds', ['funds']),
    transferBalance() {
      if (this.walletFrom === 'Exchange wallet') return this?.balance?.[this.funds.currency_name]?.[this.funds.currency_name]?.total || 0;
      return this?.userTradingBalance?.[this.funds.currency_name]?.total || 0;
    },
  },
  methods: {
    ...mapActions('funds', ['apiCreateFundTransfer']),
    handleSwapWallets() {
      const walletFrom = this.walletFrom;
      this.walletFrom = this.walletTo;
      this.walletTo = walletFrom;
    },
    handleCreateTransfer() {
      this.validateForm().then(() => {
        this.apiCreateFundTransfer({ amount: this.walletAmount, type: this.walletFrom }).then(() => {
          this.$emit('close');
        });
      });
    },
  },
};
</script>

<style lang="scss">
.TransferModal_Title {
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  margin: 0 0 28px 0;
}

.TransferModal_Wallets {
  padding: 0 0 0 62px;
  position: relative;
}

.TransferModal_Wallet {
  margin: 0 0 20px 0;
  label {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.04em;
  }
  .v-select__selection--comma {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
  }
}

.TransferModal_Amount {
  .text-field-input-root .TextFieldInput_label label {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.04em;
  }
}

.TransferModal_Swap {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  img:nth-child(3) {
    transform: rotate(180deg);
  }
  &Icon {
    width: 36px;
    height: 36px;
    background: #2af3f3;
    border-radius: 1.6px;
    margin: 10px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &Bg {
      width: 24px;
      height: 24px;
      background: #2d4d70;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        transform: rotate(90deg);
      }
    }
  }
}

.TransferModal_Available {
  display: block;
  width: 100%;
  text-align: left;
  font-size: 12px;
}

.TransferModal_Buttons {
  display: flex;
  margin: 20px 0 0 0;
  button {
    //height: 46px;
    width: 50%;
    //font-size: 12px;
    &:first-child {
      margin: 0 5px 0 0;
    }
    &:last-child {
      margin: 0 0 0 5px;
    }
  }
}
</style>
