<template>
  <div class="MonthRate_root">
    <div class="MonthRate_wrapper">
      <div class="MonthRate_block">
        <div class="MonthRate_item">{{ rate.item }}</div>
        <div class="MonthRate_rate">
          <div class="MonthRate_rate-box">
            <span>
              <SvgIcon
                v-if="rate.type === 'plus'"
                icon="littleGreenArrow"
              ></SvgIcon>
              <SvgIcon
                v-else
                icon="littleRedArrow"
              ></SvgIcon>
            </span>
          </div>
          <div class="MonthRate_rate-value">{{ rate.value }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/_common/SvgIcon";
export default {
  components: {
    SvgIcon
  },
  props: {
    rate: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
@import './MonthRate.scss';
</style>
