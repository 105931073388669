<template>
  <section class="fixed top-0 bottom-0 left-0 right-0 z-[9999]">
    <div class="flex items-center justify-center w-full h-full bg-[#000]/[0.65]">
      <div
        class="shadow-[0px_8px_10px_rgba(34, 39, 47, 0.14)] relative rounded-[6px] bg-[#1e3650]"
        :style="styles"
      >
        <div
          @click="onClose"
          class="shadow-btn-hover-default bg-blue-500 hover:bg-[#a5ffff] flex items-center justify-center absolute right-[16px] top-[16px] w-[32px] h-[32px] rounded-full transition cursor-pointer"
        >
          <iconClose fillColor="#0F1E30" />
        </div>
        <div class="p-[30px] min-w-[150px] min-h-[100px]">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { iconClose } from '@/assets/icons/arrows'
export default {
  components: {
    iconClose,
  },
  props: {
    styles: {
      type: String,
      default: '',
    },
  },
  methods: {
    onClose() {
      this.$emit('close')
    },
  },
};
</script>